import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import JSONData from '../../conf/label-maker.json';
import { setPrint as setPrintAction } from '../../state/actions';
import { getLabelPrint, getLabelShape } from '../../state/selectors';
import { getValueFromObject } from '../../utils/js/get-value-from-object';
import LabelMakerComponents from './label-maker-components';
import labelMakerPrintStyles from './label-maker-print.module.scss';

const LabelMakerPrint = ({
  currentShape, currentPrint, setPrint,
}) => {
  const LABEL_MIN_WIDTH = 4;
  const LABEL_MAX_WIDTH = 12;

  // Page dimensions (with border)
  const PAGE_HEIGHT = 269.6;
  const PAGE_WIDTH = 182.6;

  let numberMax = 10;

  const PreviewShape = LabelMakerComponents.shape[currentShape];
  const previewWidth = currentPrint.width * 10;
  const previewHeight = previewWidth / (getValueFromObject(JSONData, `labels.${currentShape}.ratio`) || 1);

  function computeNumberMax() {
    const nbPerLine = Math.floor(PAGE_WIDTH / previewWidth);
    const nbPerColumn = Math.floor(PAGE_HEIGHT / previewHeight);
    numberMax = nbPerLine * nbPerColumn;
    if (currentPrint.number > numberMax) {
      setPrint({
        number: numberMax,
      });
    }
  }

  function decreaseSize() {
    setPrint({
      width: currentPrint.width > LABEL_MIN_WIDTH ? currentPrint.width - 0.5 : LABEL_MIN_WIDTH,
    });
    computeNumberMax();
  }

  function increaseSize() {
    setPrint({
      width: currentPrint.width < LABEL_MAX_WIDTH ? currentPrint.width + 0.5 : LABEL_MAX_WIDTH,
    });
    computeNumberMax();
  }

  function setNumber(event) {
    setPrint({
      number: event.target.value,
    });
  }

  computeNumberMax();

  const numberOptions = [];
  for (let i = 1; i <= numberMax; i += 1) {
    numberOptions.push(
      <option value={i} key={i}>{i}</option>,
    );
  }

  const previewLabels = [];
  for (let i = 0; i < currentPrint.number; i += 1) {
    previewLabels.push(
      <PreviewShape
        key={`preview-${i}`}
        width={previewWidth}
        height={previewHeight}
      />,
    );
  }

  return (
    <div>
      <div className="notification is-info is-light has-icon">
        <span className="icon">
          <i className="fas fa-info-circle fa-lg" />
        </span>
        <span className="p-l-lg">
          <FormattedMessage id="label-maker.print.help" />
        </span>
      </div>
      <div className="columns">
        <div className="column">
          <form>
            <div className="field">
              <label className="label" htmlFor="font-size">
                <FormattedMessage id="label-maker.print.width" />
              </label>

              <div className="control has-text-centered">
                <button type="button" className="button is-info is-light vmiddle" onClick={decreaseSize}>
                  <span className="icon">
                    <i className="fas fa-minus" />
                  </span>
                </button>
                <div className={`${labelMakerPrintStyles.shape} vmiddle`}>
                  <div className={`${labelMakerPrintStyles.arrowsContainer} vmiddle`}>
                    <i className={`icon-${currentShape}-outline`} style={{ fontSize: `${currentPrint.width * 10}px` }} aria-hidden="true" />
                    <i className={`${labelMakerPrintStyles.arrowLeft} fas fa-chevron-left`} />
                    <i className={`${labelMakerPrintStyles.arrowRight} fas fa-chevron-right`} />
                  </div>
                  <p className="is-size-5">
                    <b className="p-r-xs">{currentPrint.width}</b>
                    cm
                  </p>
                </div>
                <button type="button" className="button is-info is-light vmiddle" onClick={increaseSize}>
                  <span className="icon">
                    <i className="fas fa-plus" />
                  </span>
                </button>
              </div>
            </div>
            <div className="field columns m-t-md">
              <div className="column">
                <label className="label p-t-sm" htmlFor="number">
                  <FormattedMessage id="label-maker.print.number" />
                </label>
              </div>
              <div className="column">
                <div className="control has-icons-left">
                  <div className="select">
                    <select id="number" value={currentPrint.number} onChange={setNumber.bind(this)}>
                      {numberOptions}
                    </select>
                  </div>
                  <div className="icon is-small is-left">
                    <i className="fas fa-th" />
                  </div>
                </div>
              </div>
            </div>
            <p className="has-text-grey-light text-smaller">
              <FormattedMessage id="label-maker.print.help2" />
            </p>
          </form>
        </div>
        <div className="column">
          <div className={`${labelMakerPrintStyles.a4} card`}>
            {previewLabels}
          </div>
        </div>
      </div>
    </div>
  );
};

LabelMakerPrint.propTypes = {
  currentShape: PropTypes.string,
  currentPrint: PropTypes.object,
  setPrint: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentShape: getLabelShape(state),
  currentPrint: getLabelPrint(state),
});

const mapDispatchToProps = {
  setPrint: setPrintAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LabelMakerPrint));
