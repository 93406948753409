import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import LabelMaker from '../components/label-maker/label-maker';
import LabelMakerPrint from '../components/label-maker/label-maker-print';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { localizedUrl } from '../utils/js/locale-utils';

const CreatePrintPage = ({ intl, location, pageContext }) => (
  <Layout>
    <SEO title={intl.formatMessage({ id: `label-maker.title.${pageContext.category}` })} />
    <Helmet>
      <link rel="canonical" href={localizedUrl(`${pageContext.slug}/shape`)} />
    </Helmet>
    <section className="section">
      <div className="container">
        <FormattedMessage id="label-maker.intro" />
      </div>
    </section>
    <section className="hero is-light">
      <div className="hero-body section" id="label-maker-body">
        <div className="container">
          <h1 className="title is-3">
            <FormattedMessage id={`label-maker.categories.${pageContext.category}.title`} />
          </h1>
          <LabelMaker
            location={location}
            title={`label-maker.categories.${pageContext.category}.title`}
            category={pageContext.category}
            categorySlug={pageContext.slug}
          >
            <LabelMakerPrint />
          </LabelMaker>
        </div>
      </div>
    </section>
  </Layout>
);

CreatePrintPage.propTypes = {
  intl: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

export default injectIntl(CreatePrintPage);
